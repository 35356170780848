import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "~/components/Section"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Test" />
    <Section>
      <h1>
        Hello you :)
      </h1>
      <p>
        Congrats, this is only a test. If I can help you no matter what just reach me out!
      </p>
    </Section>
  </Layout>
)

export default NotFoundPage
